<template>
  <div>
    <b-card>
      <b-overlay
        :show="!payments"
        rounded="sm"
      >
        <div class="invoice-table">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            v-if="payments"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Invoice No -->
              <span v-if="props.column.field === 'invoice_no'">
                <router-link
                  :to="`/invoice/current/${props.row.invoice}`"
                >
                  {{ `#${props.row.invoice}` }}
                </router-link>
              </span>
              <span
                v-if="props.column.field === 'open'"
                class="btn btn-success btn-sm "
              >
                <router-link
                  :to="`/invoice/current/${props.row.invoice}`"
                  style="color: white"
                >
                  Open
                </router-link>
              </span>
              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'customStatus'">
                <b-badge
                  :variant="statusVariant(props.row.customStatus)"
                  style="padding:8px 15px"
                >
                  {{ props.row.customStatus }}
                </b-badge>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap mt-1">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10','25','50']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-overlay>
    </b-card><br>

    <!-- Only if the user has balance payments -->
    <!-- title -->
    <b-card v-if="rows2.length > 0">
      <div class="invoice-table">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-2">
            Balance Payments
          </h3>
        </div>
        <vue-good-table
          v-if="rows2.length > 0"
          :columns="columns"
          :rows="rows2"
          :rtl="direction"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Invoice No -->
            <span v-if="props.column.field === 'invoice_no'">
              <router-link
                :to="`/invoice/current/${props.row.invoice}`"
              >
                {{ `#${props.row.invoice}` }}
              </router-link>
            </span>
            <span
              v-if="props.column.field === 'open'"
              class="btn btn-success btn-sm "
            >
              <router-link
                :to="`/invoice/current/${props.row.invoice}`"
                style="color: white"
              >
                Open
              </router-link>
            </span>
            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'customStatus'">
              <b-badge
                :variant="statusVariant(props.row.customStatus)"
                style="padding:8px 15px"
              >
                {{ props.row.customStatus }}
              </b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BCard, BOverlay, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import moment from 'moment'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    BCard,
    BBadge,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      downloading: false,
      columns: [
        {
          label: 'Invoice',
          field: 'invoice_no',
          sortable: false,
        },
        {
          label: 'Open Invoice',
          field: 'open',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'customAmount',
          sortable: true,
          sortFn: this.sortFnAmount,
        },
        {
          label: 'Date',
          field: 'customDate',
          sortable: true,
          sortFn: this.sortFnPeriod,
        },
        {
          label: 'Method',
          field: 'customMethod',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'customStatus',
          sortable: false,
        },
      ],
      rows: [],
      rows2: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Canceled      : 'light-warning',
        Paid : 'light-success',
        Failed     : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    payments() {
      return this.$store.state.invoice.paymentRegisterUser
    },
  },
  created() {
    this.fetchPaymentRegisterUser()
  },
  mounted() {
    const refreshId = setInterval(() => {
      if (this.payments) {
        this.getCustomPayments(this.payments)
        // remove balance payment from this.payments
        this.rows = this.rows.filter(item => item.customMethod !== 'Referral balance applied')
        // in the rows2 show only balance payments
        this.rows2 = this.rows2.filter(item => item.customMethod === 'Referral balance applied')
        clearInterval(refreshId)
      }
    }, 100)
  },
  methods: {
    getCustomPayments(payments) {
      const cnt = payments.length
      let x = 0
      for (x = 0; x < cnt; x += 1) {
        const cur = payments[x].invoice_model ? payments[x].invoice_model.currency : '€'
        const cusAmount = payments[x].payment_value ? `${cur} ${parseFloat(payments[x].payment_value).toFixed(2)}` : 'N/A'
        const item = {
          uid: payments[x].uid,
          // If invoice model is null: N/A
          invoice: payments[x].invoice_model ? `${payments[x].invoice_model.id}` : 'N/A',
          customAmount: cusAmount,
          customDate: payments[x].payment_timestamp,
          // if customMethod is null: N/A
          customMethod: payments[x].paidWith ? this.getMethod(payments[x].paidWith) : 'Referral balance applied',
          customStatus: this.getStatus(payments[x].result),
          result: payments[x].result,
          currency: payments[x].currency,
          payment_value: payments[x].payment_value,
          payment_timestamp: payments[x].payment_timestamp,
        }
        if (item.customAmount.includes('RON')) {
          item.customAmount = 'RON '
          item.customAmount += (payments[x].payment_value / payments[x].invoice_model.exchange_rate).toFixed(2)
        }

        this.rows.push(item)
        this.rows2.push(item)
      }
    },
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    fetchPaymentRegisterUser() {
      this.$store.dispatch('invoice/fetchPaymentRegisterUser')
        .catch(error => { console.log(error) })
    },
    getDomain(row) {
      if (row.network_type === 'invoice') {
        return `#${row.invoice}` ?? 'N/A'
      }

      if (row.network_type === 'subdomain') {
        if (row.subdomain) return `${row.subdomain}.trafficmanager.com`
        return 'N/A'
      }

      return 'N/A'
    },
    getInvoice(row) {
      return `#${row.invoice}` ?? 'N/A'
    },
    getMethod(me) {
      if (me === 'creditCard') return 'Credit Card'
      if (me === 'credit_card') return 'Credit Card'
      if (me === 'payPal') return 'PayPal'
      if (me === 'wire') return 'Wire Transfer'
      if (me === 'Referral ballance applied.') return 'Referral balance applied'
      if (me === 'btc') return 'BTC Payment'
      if (me === 'crypto') return 'Crypto Payment'
      if (me === 'Stripe') return 'Stripe'
      if (me === null) return 'N/A'
      return 'N/A'
    },
    getStatus(txt) {
      if (txt === 'canceled') return 'Canceled'
      if (txt === 'paid') return 'Paid'
      return 'Failed'
    },
    sortFnPeriod(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Date.parse(this.filterFormatDate(rowX.payment_timestamp))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.payment_timestamp))// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    sortFnAmount(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = this.getAmount(rowX)
      const value2 = this.getAmount(rowY)
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    getAmount(item) {
      const exchange_rate = 4.9264

      const value = parseFloat(item.payment_value)
      const cur = item.currency.toUpperCase()
      if (cur === 'RON') return value / exchange_rate
      return value
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.invoice-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }
}
</style>
